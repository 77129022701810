import * as React from "react"

function Nlp(props) {
  return (
    <svg
      viewBox="0 0 201 86"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinecap="square"
      strokeMiterlimit={1.5}
      {...props}
    >
      <text
        x={6.209}
        y={85.069}
        fontFamily="'AvenirNext-Bold','Avenir Next',sans-serif"
        fontWeight={700}
        fontSize={12.938}
        fill="#615c5c"
      >
        {"C"}
        <tspan
          x="17.251px 30.989px 43.742px 54.752px 64.551px 75.254px 84.794px 89.928px 103.666px 116.419px 120.455px 129.753px 140.553px 149.884px 159.263px 169.062px 184.479px"
          y="85.069px 85.069px 85.069px 85.069px 85.069px 85.069px 85.069px 85.069px 85.069px 85.069px 85.069px 85.069px 85.069px 85.069px 85.069px 85.069px 85.069px"
        >
          {"ONVERSION SYSTEMS"}
        </tspan>
      </text>
      <text
        x={-0.599}
        y={70.248}
        fontFamily="'AvenirNext-Bold','Avenir Next',sans-serif"
        fontWeight={700}
        fontSize={30.415}
        fill="#615c5c"
      >
        {"L"}
        <tspan
          x="20.506px 46.186px 77.043px 87.074px 100.155px 110.186px 138.113px 169.812px"
          y="70.248px 70.248px 70.248px 70.248px 70.248px 70.248px 70.248px 70.248px"
        >
          {"PG - CNG"}
        </tspan>
      </text>
      <path
        d="M37.227 41.209H7.118L.155 34.364v-5.648h6.963l3.921-17.05-5.427-5.227V.183h23.437l8.178 4.996V1.241h28.625l8.562 6.546v6.268h-8.562l-4.503 27.154H45.598l-4.565-3.138h-3.806v3.138zM78.245 1.241v5.198l4.12 5.149-2.231 16.222-8.062.459v5.9l7.867 7.938h50.898l2.832-12.097-8.76-8.283h-16.4l1.058-6.576h10.032V7.862l-8.714-6.621h-32.64zM140.034 1.241v5.198l4.768 5.371-3.251 14.751-6.576 1.03v6.204l6.576 8.062h33.424v-7.193l-4.227-3.677v-1.599h16.11c11.662-1.425 13.631-8.373 13.255-14.15-.502-7.696-9.996-13.855-16.728-13.997h-43.351z"
        fill="#ff0015"
      />
      <path
        d="M6.358.901V5.6l6.589 1.149-5.202 22.765H.901v3.885l26.276.475.733-5.516h-6.704l2.125-13.867 12.785 18.908h14.528l5.55-27.16 9.13-.376.516-3.925H37.917v3.441l6.031.221-2.271 13.856L28.707.901H6.358zM110.436 1.956H78.892v3.616h5.211l-3.329 22.959-7.948.401v4.402h48.618l3.299-10.808h-11.768l-6.473 6.323H95.791l4.633-23.277h9.445l.567-3.616zM181.559 1.988h-40.746v3.319h6.35l-4.924 21.906-6.487 1.026v4.779l30.359.319.487-3.88-7.95-.312 1.118-8.781h23.229c8.905-.699 12.357-15.744-1.436-18.376z"
        fill="none"
        stroke="#fff"
      />
      <path
        d="M162.51 7.327l-1.414 8.202h11.195c3.97.152 5.577-7.832 1.009-8.202h-10.79z"
        fill="none"
        stroke="#fff"
      />
    </svg>
  )
}

export default Nlp
